<template>
  <div class="detail-box w">
    <h3 class="titlefont15">{{ classQuery.albumName }}</h3>
    <div class="oneline">
      <div class="taglist">
        <!-- <div class="tag-item tag_four">专四</div>
        <div class="tag-item tag_free">免费</div> -->
        <div v-if="album.isTop == 'Y'" class="tag-item tag_free">推荐</div>
        <div v-show="album.isNew" class="tag-item tag_new">NEW</div>
      </div>
      <!-- <div class="join">加入学习群</div> -->
      <el-popover placement="bottom" trigger="click">
        <div class="saoyisao">
          <img src="./images/code.png" alt="" />
          <p class="font16400 grey1" style="text-align: center">微信扫一扫</p>
        </div>

        <div slot="reference" class="join">加入学习群</div>
      </el-popover>
    </div>
    <div class="vediobox">
      <div class="vediocontent">
        <!-- <div class="prism-player" id="J_prismPlayer"></div> -->
        <Video
          :encryptType="1"
          :vid="actVideoId"
          :classId="actClassId"
          :albumId="album.id"
          :playauth="playAuth"
          :defaltprogress="defaultprogress"
          ref="player"
          :useFlashPrism="false"
          :autoplay="true"
          :isLive="false"
          :showBuffer="true"
          :cover="cover"
          :disableSeek="true"
          showBarTime="500"
          width="100%"
          height="100%"
          controlBarVisibility="always"
          :isVideo="isVideo"
        ></Video>
      </div>
      <div class="vedionavlist">
        <div class="vedionav">
          <div class="navbox">
            <h4 class="titlefont13">
              课程目录<span class="beizhufont1"
                >（{{ activeindex }}/{{ classList.length }}）</span
              >
            </h4>
            <img
              @click="isShowNav = !isShowNav"
              v-if="isShowNav"
              class="isupdownpdf"
              src="./images/down.png"
              alt=""
            />
            <img
              @click="isShowNav = true"
              v-else
              class="isupdownpdf"
              src="./images/up.png"
              alt=""
            />
          </div>
          <ul v-show="isShowNav">
            <li
              :class="
                actClassId == classes.id ? 'active titlefont2' : 'titlefont2'
              "
              v-for="(classes, index) in classList"
              :key="classes.id"
              @click="changeClass(classes.videoId, classes.id, index)"
            >
              <p>{{ classes.className }}</p>
              <span>{{ classes.newduration }}分钟</span>
            </li>
          </ul>
        </div>
        <div class="pdf">
          <div class="navbox">
            <h4 class="titlefont13">课程资料</h4>
            <img
              @click="isShowPdf = !isShowPdf"
              v-if="isShowPdf"
              class="isupdownpdf"
              src="./images/down.png"
              alt=""
            />
            <img
              @click="isShowPdf = true"
              v-else
              class="isupdownpdf"
              src="./images/up.png"
              alt=""
            />
          </div>
          <ul v-show="isShowPdf">
            <li
              class="titlefont2"
              v-for="pdfclasses in classList"
              :key="pdfclasses.id"
              v-show="pdfclasses.pdfStatus == 'Y'"
            >
              <p>
                <i class="pdficon"></i
                ><a target="_blank" :href="baseUrl + pdfclasses.pdfPath"
                  >{{ pdfclasses.className }}.pdf</a
                >
              </p>
              <span>{{ pdfclasses.pdfSize }}M</span>
            </li>
            <!-- <li class="titlefont2">
              <p><i class="pdficon"></i><a href="">专四考试A卷.pdf</a></p>
              <span>20.8M</span>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="introducebox">
      <div class="titlebox"><p class="titlefont1">课程介绍</p></div>
      <div class="introduce">
        <!-- <p>
          笔试的第一道大题，相对而言，是专四中最容易得分的地方。课节播放页面的“资料”位置，我们为大家精心总结了专四语法相关的重点难点，全部来自权威书籍教材，怏速帮你避坑！介词需要掌握《现西》
          课后出现的动词＋介词的固定搭配；通过反复阅读，总结介词用法、区别，加深自己对各类介词的理解是十分重要的！这样就算在考试中遇到知识盲区，也可以轻而易举地“猜”出正确答案。而冠词，就更加简单啦！掌握名词的词性规律，记清”特殊“名词的阴阳性，以及不填冠词的情况，考试时看清楚单复数，就能够拿到此类题的高分。
        </p> -->
        <p v-html="album.remark"></p>
      </div>
    </div>
    <div class="recommend">
      <div class="recommendhead flexjustbeteen">
        <div class="titlebox"><p class="titlefont1">课程推荐</p></div>
        <div class="imgbutton flexjustcenter cursorpointer" @click="changepage">
          <img src="./images/flash.png" alt="" />
          <p class="font16400 green1">换一批</p>
        </div>
      </div>
      <div class="courselist">
        <div
          class="courseitem"
          v-for="albums in albumList"
          :key="albums.id"
          @click="
            courseDetail(albums.id, albums.albumName, albums.subjectId, albums)
          "
        >
          <img :src="albums.coverId" alt="" />
          <div class="collect">共{{ albums.total }}集</div>
          <div class="oneline">
            <div class="taglist">
              <!-- <div class="tag-item tag_four">专四</div>
              <div class="tag-item tag_free">免费</div> -->
              <div v-if="albums.isTop == 'Y'" class="tag-item tag_free">
                推荐
              </div>
              <div v-show="albums.isNew" class="tag-item tag_new">NEW</div>
            </div>
            <div class="studynum">
              <span>{{ albums.favorNum }}人已学过</span>
            </div>
          </div>
          <div class="twoline">
            <p>{{ albums.albumName }}</p>
          </div>
        </div>
        <!-- <div class="courseitem">
          <img src="./images/course.png" alt="" />
          <div class="collect">共5集</div>
          <div class="oneline">
            <div class="taglist">
              <div class="tag-item tag_four">专四</div>
              <div class="tag-item tag_free">免费</div>
            </div>
            <div class="studynum"><span>1368人已学过</span></div>
          </div>
          <div class="twoline">
            <p>2023年专八真题及答案</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  classList, updateFavor, albumList
} from "@/api"
import Video from "../../components/Video"
export default {
  name: "CourseDetail",
  components: {
    Video
  },
  data () {
    return {
      input3: '',
      // id: 0,
      // albumName: '',
      album: {},//专辑
      remark: '',
      classQuery: {
        albumId: 0,
        albumName: '',
        pageNum: 1,
        pageSize: 1000,
      },
      classList: [],
      classTotal: 0,
      activeindex: 1,
      baseUrl: window.__baseUrl__,

      cover: '', //视频封面
      // vid: '', //视频vid
      playAuth: '', //鉴权地址

      actVideoId: '',
      actClassId: '',
      defaultprogress: '',
      isVideo: "Y",
      subjectId: '',
      albumList: [],
      albumTotal: 0,
      albumParam: {
        pageNum: 1,
        pageSize: 5,
        albumName: '',
        subjectId: '',
      },
      isShowNav: true,
      isShowPdf: false
    }
  },
  mounted () {
    this.album = this.$route.params.albums
    this.classQuery.albumId = this.$route.query.albumId
    this.classQuery.albumName = this.$route.query.albumName
    this.albumParam.subjectId = this.$route.query.subjectId ? this.$route.query.subjectId : ''
    this.getClassList()
    this.updateFavorBySel()
    this.getAlbumList()
  },
  methods: {
    courseDetail (id, albumName, subjectId, album) {
      // 
      // this.$router.push({ name: 'CourseDetail', query: { albumId: id, albumName, subjectId }, params: { remark } })
      this.album = album
      this.classQuery.albumId = id
      this.classQuery.albumName = albumName
      this.albumParam.subjectId = subjectId
      this.getClassList()
      this.updateFavorBySel()
    },
    getAlbumList () {
      albumList(this.albumParam).then((d) => {
        if (d.code == 200) {
          console.log(d, 'xxx')
          this.albumList = d.rows
          this.albumTotal = d.total
        }

      })

    },
    changepage () {
      if (this.albumParam.pageNum >= Math.ceil(this.albumTotal / this.albumParam.pageSize)) {
        this.albumParam.pageNum = 1
      } else {
        this.albumParam.pageNum++
      }
      this.getAlbumList()
    },
    updateFavorBySel () {
      updateFavor({ albumId: this.classQuery.albumId })
    },
    changeClass (vid, cid, index) {
      this.actVideoId = vid
      this.actClassId = cid
      this.activeindex = index + 1
      this.defaultprogress = ''
    },
    getClassList () {

      classList(this.classQuery).then((d) => {
        if (d.code == 200) {
          console.log(d, 'ddd')
          this.classList = d.rows
          this.actClassId = d.defaultPlayInfo.classId
          this.defaultprogress = Math.round(d.defaultPlayInfo.progress / 1000)
          this.classList.forEach((item) => {
            item.pdfSize = Math.round(item.pdfSize * 100 / 1024 / 1024) / 100
            item.newduration = Math.round(item.duration / 60)
            if (this.actClassId == item.id) {
              this.actVideoId = item.videoId
              this.actClassId = item.id
              this.isVideo = item.isVideo
            }
          })
          this.classTotal = d.total
          // this.getVideoPlayAuth(this.actVideoId, this.actClassId)

        }

      })

    },


  }
}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #eee;
}
/*定义滑块 样式*/
::-webkit-scrollbar-thumb {
  height: 100px; /* 滚动条滑块长度 */
  background-color: #ccc;
}
.imgbutton {
  margin-top: 40px;
  img {
    width: 20px;
    height: 20px;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.detail-box {
  padding: 130px 0 80px;
  .oneline {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  .taglist {
    display: flex;

    .tag-item {
      width: 40px;
      height: 24px;
      border-radius: 4px;
      margin-right: 10px;

      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      line-height: 24px;
    }
    .tag_four {
      background: linear-gradient(
        135deg,
        rgba(255, 138, 20, 1) 0%,
        rgba(254, 197, 101, 1) 100%
      );
    }
    .tag_new {
      background: linear-gradient(
        90deg,
        rgba(76, 224, 215, 1) 0%,
        rgba(81, 159, 232, 1) 100%
      );
    }
    .tag_vip {
      background: linear-gradient(
        90deg,
        rgba(224, 76, 76, 1) 0%,
        rgba(232, 161, 82, 1) 100%
      );
    }
    .tag_free {
      background: linear-gradient(
        90deg,
        rgba(224, 76, 76, 1) 0%,
        rgba(232, 161, 82, 1) 100%
      );
    }
  }
  .join {
    font-size: 18px;
    font-weight: 400;
    text-decoration-line: underline;
    color: rgba(67, 207, 124, 1);
    cursor: pointer;
  }
  .vediobox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    .vediocontent {
      // width: 997px;
      width: 66%;
      height: 562px;
      background: rgba(150, 150, 150, 1);
    }
    .vedionavlist {
      // width: 493px;
      width: 33%;
      height: 562px;
      overflow: auto;

      .vedionav,
      .pdf {
        // width: 493px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.6);
        border: 2px solid rgba(255, 255, 255, 1);
        backdrop-filter: blur(20px);
        padding: 20px 25px;

        a {
          text-decoration-line: underline;
        }
      }
      .pdf {
        margin-top: 10px;
      }
      .navbox {
        display: flex;
        justify-content: space-between;
      }
      .isupdown {
        // display: none;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .isupdownpdf {
        // display: none;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      ul {
        margin-top: 10px;
        li {
          display: flex;
          justify-content: space-between;
          height: 36px;
          line-height: 36px;
          margin-top: 5px;
          padding: 0 8px;
          cursor: pointer;
          span {
            color: rgba(170, 170, 170, 1);
          }
          p {
            display: flex;
            align-items: center;
            .pdficon {
              display: block;
              width: 24px;
              height: 24px;
              background: url("./images/pdf.png");
              margin-right: 4px;
            }
          }
        }
        .active {
          height: 36px;
          border-radius: 4px;
          background: rgba(255, 255, 255, 1);
          color: rgba(67, 207, 124, 1);
        }
      }
    }
  }
  .titlebox {
    height: 21px;
    border-left: 3px solid rgba(67, 207, 124, 1);
    line-height: 21px;
    padding-left: 7px;
    margin-top: 30px;
  }
  .introducebox {
    .introduce {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 1.5;
    }
  }
  .courselist {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // justify-content: space-between;
    .courseitem {
      width: 284px;
      height: 250px;
      cursor: pointer;
      margin: 16px 0px 50px;
      margin-right: 15px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(20px);
      border: 2px solid rgba(255, 255, 255, 1);
      position: relative;
      img {
        width: 284px;
        height: 160px;
        position: absolute;
        top: -2px;
        left: -2px;
      }
      // .coursedes {

      //   border-top: none;
      // }
      .collect {
        position: absolute;
        right: 16px;
        top: 125px;
        height: 24px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.5);
        padding: 1px 9px 1px 9px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
      .oneline {
        margin-top: 170px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        .taglist {
          display: flex;

          .tag-item {
            width: 40px;
            height: 24px;
            border-radius: 4px;
            margin-right: 10px;

            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            text-align: center;
            line-height: 24px;
          }
          .tag_four {
            background: linear-gradient(
              135deg,
              rgba(255, 138, 20, 1) 0%,
              rgba(254, 197, 101, 1) 100%
            );
          }
          .tag_new {
            background: linear-gradient(
              90deg,
              rgba(76, 224, 215, 1) 0%,
              rgba(81, 159, 232, 1) 100%
            );
          }
          .tag_vip {
            background: linear-gradient(
              90deg,
              rgba(224, 76, 76, 1) 0%,
              rgba(232, 161, 82, 1) 100%
            );
          }
          .tag_free {
            background: linear-gradient(
              90deg,
              rgba(224, 76, 76, 1) 0%,
              rgba(232, 161, 82, 1) 100%
            );
          }
        }
        .studynum {
          font-size: 16px;
          font-weight: 400;
          color: rgba(170, 170, 170, 1);
        }
      }
      .twoline {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        margin-top: 14px;
        padding: 0 16px;
      }
    }
  }
  .courselist:after {
    content: "";
    width: 40%;
    // height: 0;
    // visibility: hidden;
  }
}
@media (max-width: 1116px) {
  .vediocontent {
    width: 100% !important;
  }
  .vedionavlist {
    width: 100% !important;
  }
  .courseitem {
    width: 240px !important;
    height: 211px !important;
    img {
      width: 240px !important;
      height: 135px !important;
    }
  }
  .detail-box .courselist .courseitem .oneline {
    margin-top: 140px !important;
  }
  .collect {
    top: 100px !important;
  }
}
</style>